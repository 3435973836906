import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Waypoint from 'react-waypoint'

import Layout from '../components/layout'
import Header from '../components/Header'
import Nav from '../components/Nav'
import pic01 from '../assets/images/pic01.jpg'

const btnStyle = {
  backgroundColor: '#d065b5',
  color: 'white',
  fontWeight: 'bold',
  borderColor: 'white',
  width: '100%',
  boxShadow: 'none',
}
const btnWrapStyle = {
  width: '92%',
  textAlign: 'center',
  position: 'absolute',
  margin: 'auto',
  marginTop: '-3rem',
}

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout>
        <Helmet title="Future Transit, Thunder Bay Transit Services, tbtfuture.fyi" />

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        />
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>What is this project?</h2>
                </header>
                <p>
                  This project looks at how the existing conventional transit
                  system can be changed to better meet community needs. A new
                  network is proposed which will offer better service and grow
                  ridership.
                </p>
              </div>
              <div className="image-w-button">
                <span className="image">
                  <Link to="/map">
                    <img src={pic01} alt="" />
                    <div style={btnWrapStyle}>
                      <div className="button" style={btnStyle}>
                        View the map
                      </div>
                    </div>
                  </Link>
                </span>
              </div>
            </div>
            <div className="spotlight">
              <div className="content">
                <h2>Why are we doing this project now?</h2>
                <p>
                  Thunder Bay residents want better transit service. Using the
                  ideas of the 2012 Transit Master Plan, we have developed an
                  improved network that is possible to run with the current
                  budget.
                </p>
              </div>
            </div>
          </section>
          <section id="methods" className="main special">
            <header className="major">
              <h2>Project methodology</h2>
              <p>The project was completed in four phases:</p>
            </header>
            <ul className="features">
              <li>
                <span className="icon major style1 fa-bus" />
                <h3>Understand</h3>
                <p> the existing transit service</p>
              </li>
              <li>
                <span className="icon major style3 fa-code-fork" />
                <h3>Build</h3>
                <p>
                  a new network based on public input and technical analyses
                </p>
              </li>
              <li>
                <span className="icon major style5 fa-search" />
                <h3>Look</h3>
                <p>
                  at both new and existing networks to see what works better
                </p>
              </li>
              <li>
                <span className="icon major style5 fa-calendar-check-o" />
                <h3>Create</h3>
                <p> a plan to build the selected network</p>
              </li>
            </ul>
          </section>
          <section id="findings" className="main">
            <header className="major">
              <h2>What we found</h2>
            </header>
            <p>
              Of the 17 routes, routes 1, 2, and 3M carry over half of the daily
              trips. A lot of people are travelling between the north and south
              cores and to key destinations like Lakehead University,
              Confederation College and the regional hospital. There are some
              long, curved routes outside the city core that run with few riders
              most of the day.
            </p>
            <ul className="textcolumns">
              <li>
                <h2>What we heard</h2>
                <p>
                  Today, customers have issues with long travel times,
                  unreliable service, long wait times for buses and a lack of
                  service on evenings and weekends. We hear your concerns and
                  are making reasonable improvements.
                </p>
              </li>
              <li>
                <h2>Who is involved in this study?</h2>
                <p>
                  We are working closely with key stakeholders and groups from
                  across the Thunder Bay community. This includes students,
                  seniors, First Nations communities, individuals with
                  disabilities, transit staff, and many others.
                </p>
              </li>
              <li>
                <h2>
                  How will the proposed new bus network be different from the
                  current one?
                </h2>
                <p>
                  Parts of some routes have been added to others or taken away
                  to create simple, straight and fast bus routes. The biggest
                  change is the proposed on-demand service for bus routes that
                  carry few people. Some successful routes have remained
                  unchanged.
                  <br />
                  <br />
                  <Link to="/map" className="button">
                    check out the map
                  </Link>
                </p>
              </li>
              <li>
                <h2>Are the new bus routes final?</h2>
                <p>
                  Unless we missed anything big, the proposed network is not
                  expected to change much before being shown to City Council,
                  who will decide if the new network is put in place. Something
                  big could be missing service to an important place, like a
                  shopping centre, hospital or school.
                </p>
              </li>
              <li>
                <h2>When will the proposed bus routes change?</h2>
                <p>
                  Once we’ve heard from you, route network recommendations will
                  be finalized and presented to City Council in a final report
                  for approval later this year. The timing of the new network
                  will be set after Council’s approval. Keep in mind that
                  services will be changed gradually, so that costs do not
                  increase and so customers are able to get used to service
                  changes.
                </p>
              </li>
              <li>
                <h2>Alternative On-Demand Service</h2>
                <p>
                  Alternative on-demand service offers public transit in areas
                  with few riders. On-demand service has been used in many
                  cities across North America who face similar barriers to run
                  public transit in spread out communities.
                  <br />
                  <br />
                  <Link to="/on-demand" className="button">
                    learn more...
                  </Link>
                </p>
              </li>
            </ul>
          </section>

          <section id="whats-next" className="main special">
            <header className="major">
              <h2>What's next?</h2>
              <p>
                To show you our proposed network, we will host drop-in sessions
                at the dates and locations below. We’ll be there, ready to chat
                about the proposed changes how they will provide you with a more
                reliable and simpler transit service.
              </p>
            </header>
            <ul className="statistics">
              <li className="style1">
                <span className="icon fa-calendar" />
                <strong>
                  Feb 5<br />
                  8am-11am
                </strong>
                <p>Lakehead University Centre Agora</p>
              </li>
              <li className="style2">
                <span className="icon fa-calendar" />
                <strong>
                  Feb 5<br />
                  1pm-4pm
                </strong>
                <p>Waterfront Terminal</p>
              </li>
              <li className="style3">
                <span className="icon fa-calendar" />
                <strong>
                  Feb 6<br />
                  8:30am-11:30am
                </strong>
                <p>City Hall Lobby</p>
              </li>
              <li className="style4">
                <span className="icon fa-calendar" />
                <strong>
                  Feb 6<br />
                  1pm-4pm
                </strong>
                <p>Intercity Shopping Centre</p>
              </li>
              <li className="style5">
                <span className="icon fa-calendar" />
                <strong>
                  Feb 7<br />
                  8am-11am
                </strong>
                <p>Confederation College SUCCI Lounge</p>
              </li>
            </ul>
            <header className="major">
              <p>
                Additional meetings will be held specifically for on-demand
                service.
              </p>
              <Link to="/on-demand" className="button">
                learn more...
              </Link>
            </header>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Index
